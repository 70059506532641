import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/home.css'; // Ensure CSS is applied

const HomePage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const sections = [
        { name: "Take Assessment", route: "/assessment", icon: "📝" },
        { name: "Live Chat", route: "/chat", icon: "💬" },
        { name: "Resources", route: "/resources", icon: "📚" },
        { name: "Meditation", route: "/meditation", icon: "🧘" },
        { name: "Journaling", route: "/journaling", icon: "📖" },
        { name: "Mood Tracker", route: "/mood-tracker", icon: "📊" },
        { name: "Forum", route: "/forum", icon: "🌐" },
        { name: "Book a Therapist", route: "/booking", icon: "📅" },
        { name: "Emergency Help", route: "/emergency-help", icon: "🚨" },
        { name: "How shall I Respond", route: "/responce-help", icon: "❓" },
        { name: "Game", route: "/game", icon: "🎮" },
    ];

    useEffect(() => {
        // Simulate a delay for loading
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000); // 2-second loading screen

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className="loading-screen">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        );
    }

    return (
        <div>
            <div className="home-grid">
                {sections.map((section) => (
                    <div
                        key={section.name}
                        className="home-card"
                        onClick={() => navigate(section.route)}
                    >
                        <h2>
                            <span className="icon">{section.icon}</span> {section.name}
                        </h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomePage;
