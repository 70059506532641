import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/chatregister.css';
import '../styles/buttons.css';

const ChatPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is authenticated and fetch user details
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(data.user);

                    // Automatically redirect if role and username exist
                    if (data.user.role && data.user.username) {
                        if (data.user.role === 'helpseeker') {
                            navigate('/helpseeker');
                        } else if (data.user.role === 'volunteer') {
                            navigate('/volunteer');
                        }
                    } else {
                        setShowRoleSelection(true);
                    }
                } else {
                    setIsAuthenticated(false);
                }
            })
            .catch((err) => {
                console.error('Error checking authentication:', err);
                setIsAuthenticated(false);
            });
    }, [navigate]);

    const handleSignIn = () => {
        window.location.href = '/auth/google';
    };

    const handleLogout = () => {
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        window.location.href = '/auth/logout';
    };

    const handleUsernameCheck = () => {
        if (username.trim() === '') {
            setErrorMessage('Username cannot be empty.');
            return;
        }

        fetch(`/auth/check-username?username=${username}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to check username.');
                }
                return response.json();
            })
            .then((data) => {
                if (data.exists) {
                    setErrorMessage('Username already exists, please choose another.');
                } else {
                    setErrorMessage('Username is available.');
                }
            })
            .catch((err) => {
                console.error('Error checking username:', err);
                setErrorMessage('Unable to check username. Please try again later.');
            });
    };

    const handleRoleSelection = (selectedRole) => {
        if (!username.trim()) {
            setErrorMessage('Please enter a username.');
            return;
        }

        fetch('/auth/save-user-details', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, role: selectedRole }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // Save role and username locally for persistence
                    localStorage.setItem('role', selectedRole);
                    localStorage.setItem('username', username);

                    if (selectedRole === 'helpseeker') {
                        navigate('/helpseeker');
                    } else if (selectedRole === 'volunteer') {
                        navigate('/volunteer');
                    }
                } else {
                    setErrorMessage('Error saving user details.');
                }
            })
            .catch((err) => console.error('Error saving user details:', err));
    };

    return (
        <div className="chat-page-container">
            <header className="header">
                {isAuthenticated && user ? (
                    <div className="user-info">
                        <img src={user.image} alt="User Icon" className="user-icon" />
                        <span>{user.displayName}</span>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                ) : (
                    <div className="sign-in-container">
                        <p>You need to sign in to access the chat.</p>
                        <button onClick={handleSignIn}>Sign in with Google</button>
                    </div>
                )}
            </header>

            {isAuthenticated ? (
                showRoleSelection ? (
                    <div className="role-selection">
                        <div className="username-container">
                            <label>
                                <div>👤 Want to stay mysterious? No problem! 🌟
                                    You can enter an anonymous username to keep your privacy intact.</div>
                                
                                <input
                                    className="input-field"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </label>
                            <button className="amazing-button" onClick={handleUsernameCheck}>
                                Check Username
                            </button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>

                        <div className="role-selection-container">
                            <h3>Select Your Role</h3>
                            <button
                                className="amazing-button"
                                onClick={() => handleRoleSelection('helpseeker')}
                            >
                                Helpseeker
                            </button>
                            <button
                                className="amazing-button"
                                onClick={() => handleRoleSelection('volunteer')}
                            >
                                Volunteer
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>Loading role selection...</p>
                )
            ) : (
                <div className="sign-in-container">
                    
                    
                </div>
            )}
        </div>
    );
};

export default ChatPage;
