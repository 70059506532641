import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import '../styles/chat.css';

const socket = io.connect('https://danezah.com'); // Use your production server URL

const Chat = () => {
    const { username } = useParams(); // Chat partner's username from the URL
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [user, setUser] = useState(null); // Logged-in user's details
    const textareaRef = useRef(null); // Reference for the textarea

    // Fetch logged-in user details on component mount
    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setUser(data.user); // Save logged-in user's details
                } else {
                    console.error('User not authenticated');
                }
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
            });
    }, []);

    // Fetch chat history and listen for incoming messages
    useEffect(() => {
        if (user) {
            fetch(`/messages/${username}`, { credentials: 'include' })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setMessages(data.messages); // Load chat history
                    } else {
                        console.error('Failed to load messages');
                    }
                })
                .catch((error) => console.error('Error fetching messages:', error));

            socket.on('receive_message', (message) => {
                setMessages((prev) => [...prev, message]); // Add new incoming messages
            });

            return () => socket.off('receive_message');
        }
    }, [username, user]);

    // Send a new message
    const sendMessage = () => {
        if (newMessage.trim() && user) {
            const message = {
                sender: user.username, // Logged-in user's username
                receiver: username, // Chat partner's username
                message: newMessage,
            };

            // Emit the message via WebSocket
            socket.emit('send_message', message);

            // Clear input field
            setNewMessage('');
            adjustTextareaHeight(); // Reset textarea height after sending
        }
    };

    // Adjust textarea height dynamically
    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust to content
        }
    };

    // Handle key presses in the textarea
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent newline
            sendMessage(); // Send message on Enter key
        }
    };

    return (
        <div className="chat-container">
            <h1>Chat with {username}</h1>
            <div className="chat-box">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`message ${msg.sender === user?.username ? 'sent' : 'received'}`}
                        data-timestamp={new Date(msg.timestamp).toLocaleTimeString()} // Add timestamp as a data attribute
                    >
                        <p>{msg.message}</p>
                    </div>
                ))}
            </div>

            <div className="input-container">
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);
                        adjustTextareaHeight();
                    }}
                    onKeyDown={handleKeyDown} // Handle key events
                    placeholder="Type a message..."
                    rows="1" // Initial height
                    style={{ resize: 'none', overflow: 'hidden' }} // Disable manual resizing
                ></textarea>
                <button onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
};

export default Chat;
