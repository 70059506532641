import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import "../styles/assessment.css";
import "../styles/buttons.css"
const AssessmentPage = () => {
    const [name, setName] = useState("");
    const [answers, setAnswers] = useState({});
    const [completed, setCompleted] = useState(false);
    const [nameSubmitted, setNameSubmitted] = useState(false);
    const navigate = useNavigate();
    const resultRef = useRef(null);

const questions = [
    { id: 1, text: "Over the past two weeks, have you felt little interest or pleasure in doing things?", category: "Depression" },
    { id: 2, text: "Have you felt down, depressed, or hopeless most days?", category: "Depression" },
    { id: 3, text: "Have you had trouble concentrating on everyday tasks?", category: "Depression" },
    { id: 4, text: "Do you feel tired no matter how much rest you get?", category: "Depression" },
    { id: 5, text: "Have you lost interest in activities you previously enjoyed?", category: "Depression" },
    { id: 6, text: "Have you felt that life isn’t worth living?", category: "Depression" },
    { id: 7, text: "Do you find yourself overeating or lacking appetite?", category: "Depression" },
    { id: 8, text: "Have you noticed yourself withdrawing from loved ones?", category: "Depression" },
    { id: 9, text: "How often do you feel nervous or anxious, even without reason?", category: "Anxiety" },
    { id: 10, text: "Do you feel like you cannot control your worrying?", category: "Anxiety" },
    { id: 11, text: "Have you experienced sudden feelings of fear or dread?", category: "Anxiety" },
    { id: 12, text: "How often do you feel your heart racing or trembling when stressed?", category: "Anxiety" },
    { id: 13, text: "Do you avoid specific situations because they make you anxious?", category: "Anxiety" },
    { id: 14, text: "Have you felt a constant sense of restlessness or unease?", category: "Anxiety" },
    { id: 15, text: "Do you worry excessively about small or everyday problems?", category: "Anxiety" },
    { id: 16, text: "Have you experienced shortness of breath due to anxiety?", category: "Anxiety" },
    { id: 17, text: "How often do you feel like you have too many responsibilities to manage?", category: "Stress" },
    { id: 18, text: "Do you feel like you lack control over your life?", category: "Stress" },
    { id: 19, text: "Have you been easily irritated or angry due to stress?", category: "Stress" },
    { id: 20, text: "How often do you feel physically exhausted due to stress?", category: "Stress" },
    { id: 21, text: "Have you found it difficult to relax, even during free time?", category: "Stress" },
    { id: 22, text: "How often do you have trouble falling asleep or staying asleep?", category: "Sleep and Fatigue" },
    { id: 23, text: "Do you wake up feeling unrefreshed, even after sleeping?", category: "Sleep and Fatigue" },
    { id: 24, text: "Have you noticed changes in your sleep patterns recently?", category: "Sleep and Fatigue" },
    { id: 25, text: "Do you rely on caffeine or other stimulants to get through the day?", category: "Sleep and Fatigue" },
    { id: 26, text: "How often do you feel like you lack energy throughout the day?", category: "Sleep and Fatigue" },
    { id: 27, text: "Do you feel disconnected from people who care about you?", category: "Social Connection" },
    { id: 28, text: "How often do you avoid social gatherings or events?", category: "Social Connection" },
    { id: 29, text: "Have you felt lonely, even when around others?", category: "Social Connection" },
    { id: 30, text: "Do you feel supported by family or friends during difficult times?", category: "Social Connection" },
    { id: 31, text: "Have you experienced difficulty making new friends?", category: "Social Connection" },
    { id: 32, text: "Do you find yourself repeatedly checking locks, stoves, or other items?", category: "OCD" },
    { id: 33, text: "Have you been bothered by intrusive thoughts you can’t control?", category: "OCD" },
    { id: 34, text: "Do you feel the need to perform rituals or routines excessively?", category: "OCD" },
    { id: 35, text: "Have compulsive behaviors interfered with your daily life?", category: "OCD" },
    { id: 36, text: "Do you have recurring, distressing memories of a past event?", category: "Trauma and PTSD" },
    { id: 37, text: "Have you avoided places or people that remind you of a traumatic event?", category: "Trauma and PTSD" },
    { id: 38, text: "How often do you feel jumpy or easily startled?", category: "Trauma and PTSD" },
    { id: 39, text: "Do you have trouble sleeping due to nightmares or flashbacks?", category: "Trauma and PTSD" },
    { id: 40, text: "Have you felt detached or numb since a traumatic experience?", category: "Trauma and PTSD" },
];
const options = ["Never", "Rarely", "Sometimes", "Often", "Always"];

const handleAnswerChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: value }));
};

const calculateResult = () => {
    const categoryScores = {};
    const categoryCounts = {};

    questions.forEach((question) => {
        const answer = parseInt(answers[question.id] || 0);
        categoryScores[question.category] = (categoryScores[question.category] || 0) + answer;
        categoryCounts[question.category] = (categoryCounts[question.category] || 0) + 1;
    });

    const normalizedScores = {};
    Object.keys(categoryScores).forEach((category) => {
        const maxScore = categoryCounts[category] * 4;
        normalizedScores[category] = ((categoryScores[category] / maxScore) * 100).toFixed(1);
    });

    return normalizedScores;
};

const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(answers).length < questions.length) {
        alert("Please answer all questions before submitting.");
        return;
    }
    setCompleted(true);
};

const renderResults = () => {
    const results = calculateResult();
    return (
        <div className="result" ref={resultRef}>
            <h2>Assessment Results for {name}</h2>
            {Object.keys(results).map((category) => (
                <div key={category} className="result-item">
                    <div className="category">{category}</div>
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{
                                width: `${results[category]}%`,
                                backgroundColor:
                                    results[category] > 70
                                        ? "red"
                                        : results[category] > 40
                                            ? "yellow"
                                            : "green",
                            }}
                        ></div>
                    </div>
                </div>
            ))}
            <button class="amazing-button" onClick={() => navigate("/")}>Back to Home</button>
            <button class="amazing-button" onClick={captureScreenshot}>
                Share result on story :)
            </button>
        </div>
    );
};

const handleNameSubmit = (e) => {
    e.preventDefault();
    setNameSubmitted(true);
};
    const captureScreenshot = async () => {
        if (resultRef.current) {
            try {
                const canvas = await html2canvas(resultRef.current);
                const imageDataUrl = canvas.toDataURL("image/png");

                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                if (isMobile && navigator.share) {
                    // Share on mobile devices
                    const blob = await (await fetch(imageDataUrl)).blob();
                    const file = new File([blob], "assessment-results.png", { type: "image/png" });
                    await navigator.share({
                        title: "My Assessment Results",
                        text: "Check out my mental health assessment results! Visit https://danezah.com/assessment",
                        files: [file],
                    });
                } else {
                    // Download on desktops
                    const link = document.createElement("a");
                    link.href = imageDataUrl;
                    link.download = "assessment-results.png";
                    link.click();
                }
            } catch (error) {
                console.error("Error capturing screenshot:", error);
                alert("Failed to share or download results. Please try again.");
            }
        }
    };
return (
    <div className="assessment-container">
        
        {!nameSubmitted ? (
            <form onSubmit={handleNameSubmit} className="name-form">
                <h2>Welcome! What shall we call you? 🤔</h2>
                <input
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <button class="amazing-button" type="submit">Start Assessment</button>
            </form>
        ) : !completed ? (
            <form onSubmit={handleSubmit} className="assessment-form">
                {questions.map((question) => (
                    <div key={question.id} className="question">
                        <p>{question.text}</p>
                        <div className="options">
                            {options.map((option, index) => (
                                <label key={index} className="option-label">
                                    <input
                                        type="radio"
                                        name={`question-${question.id}`}
                                        value={index}
                                        onChange={() => handleAnswerChange(question.id, index)}
                                        required
                                    />
                                    <span>{option}</span>
                                </label>
                            ))}
                        </div>

                    </div>
                ))}
                    <button class="amazing-button" type="submit">Submit Assessment</button>
                    
                </form>

        ) : (
            renderResults()
        )}
    </div>


);
};

export default AssessmentPage;