import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/journaling.css';

const JournalingPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [journal, setJournal] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(data.user);

                    if (data.user.role && data.user.username) {
                        fetchJournal(new Date());
                    } else {
                        setShowRoleSelection(true);
                    }
                } else {
                    setIsAuthenticated(false);
                }
            })
            .catch((err) => {
                console.error('Error checking authentication:', err);
                setIsAuthenticated(false);
            });
    }, []);

    const handleSignIn = () => {
        window.location.href = '/auth/google';
    };

    const handleLogout = () => {
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        window.location.href = '/auth/logout';
    };

    const handleRoleSelection = (selectedRole) => {
        if (!username.trim()) {
            setErrorMessage('Please enter a username.');
            return;
        }

        fetch('/auth/save-user-details', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, role: selectedRole }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    localStorage.setItem('role', selectedRole);
                    localStorage.setItem('username', username);

                    fetchJournal(new Date());
                    setShowRoleSelection(false);
                } else {
                    setErrorMessage('Error saving user details.');
                }
            })
            .catch((err) => console.error('Error saving user details:', err));
    };

    const fetchJournal = (date) => {
        setLoading(true);
        fetch(`/journal/entries/${date.toISOString().split('T')[0]}`, { credentials: 'include' })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setJournal(data.journal?.content || '');
                }
            })
            .catch((err) => console.error("Error fetching journal:", err))
            .finally(() => setLoading(false));
    };

    return (
        <div className="journaling-page-container">
            <header className="header">
                {isAuthenticated && user ? (
                    <div className="user-info">
                        <img src={user.image} alt="User Icon" className="user-icon" />
                        <span>{user.displayName}</span>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                ) : (
                    <div className="sign-in-container">
                        <p>You need to sign in to access the journal.</p>
                        <button onClick={handleSignIn}>Sign in with Google</button>
                    </div>
                )}
            </header>

            {isAuthenticated ? (
                showRoleSelection ? (
                    <div className="role-selection">
                        <div className="username-container">
                            <label>
                                <div>👤 Create your username for journaling:</div>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </label>
                            <button className="amazing-button" onClick={() => handleRoleSelection('journaler')}>
                                Save & Start Journaling
                            </button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    </div>
                ) : (
                    <div className="journal-container">
                        <textarea
                            className="journal-textarea"
                            value={journal}
                            onChange={(e) => setJournal(e.target.value)}
                            placeholder="Write your thoughts here..."
                        ></textarea>
                    </div>
                )
            ) : null}
        </div>
    );
};

export default JournalingPage;
