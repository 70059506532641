import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/userlist.css';

const Helpseeker = () => {
    const [volunteers, setVolunteers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Verify the user's role
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    if (data.user.role === 'helpseeker') {
                        setIsAuthorized(true);
                    } else if (data.user.role === 'volunteer') {
                        navigate('/volunteer'); // Redirect to Volunteer page if they are a volunteer
                    } else {
                        navigate('/unauthorized'); // Redirect if the role is invalid
                    }
                } else {
                    navigate('/signin'); // Redirect if not authenticated
                }
            })
            .catch((error) => {
                console.error('Error checking authorization:', error);
                navigate('/unauthorized');
            });
    }, [navigate]);

    useEffect(() => {
        if (isAuthorized) {
            // Fetch the list of volunteers
            fetch('/helpseeker/volunteers')
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setVolunteers(data.volunteers);
                    } else {
                        console.error('Error fetching volunteers');
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching volunteers:', error);
                    setLoading(false);
                });
        }
    }, [isAuthorized]);

    const handleLogout = () => {
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        window.location.href = '/auth/logout';
    };

    const startChat = (username) => {
        navigate(`/chat/${username}`);
    };

    if (!isAuthorized) {
        return <div>Checking authorization...</div>;
    }

    if (loading) {
        return <div>Loading volunteers...</div>;
    }

    return (
        <div className="user-list-container">
            <header className="header">
                <h1>Available Volunteers</h1>
                <button className="amazing-button" onClick={handleLogout}>
                    Logout
                </button>
            </header>
            <div className="user-list">
                {volunteers.map((volunteer) => (
                    <div className="user-card" key={volunteer.username}>
                        <h2>{volunteer.username}</h2>
                        <button className="chat-button" onClick={() => startChat(volunteer.username)}>
                            Chat Now
                        </button>
                    </div>
                ))}
            </div>
            <a href="https://danezah.com">
                <button className="amazing-button" > back to kit </button>
            </a>
        </div>
    );
};

export default Helpseeker;
